<template>
  <el-dialog 
    title="新增"
    :visible="visible"
    :close-on-click-modal="true"
    :before-close="handleClose"
    width="600px"
  >
    <gl-wrap right="30px">
      <el-form :model="formData" ref="form" label-width="150px" size="mini" v-loading="loading">
        <el-form-item prop="vipName" label="套餐名称" verify>
          <el-input v-model="formData.vipName" placeholder="请输入套餐名称" :maxlength="20"></el-input>
        </el-form-item>
        <el-form-item prop="vipLogo" label="VIP图片" verify :watch="formData.vipLogo">
          <gl-upload-images 
            :limit="1" 
            :type="2"
            v-model="formData.vipLogo" 
            ref="uploadImages" 
            :oldList="oldList" 
            :autoUpload="true">
          </gl-upload-images>
        </el-form-item>
        <el-form-item prop="vipPrice" label="套餐价格(元)" verify>
          <el-input v-model="formData.vipPrice" placeholder="请输入套餐价格" :maxlength="8"></el-input>
        </el-form-item>
        <el-form-item prop="discount" label="购物折扣" verify>
          <el-input v-model="formData.discount" placeholder="请输入购物折扣" :maxlength="8"></el-input>
        </el-form-item>
        <el-form-item prop="giftType" label="赠品类型" verify>
          <gl-option v-model="formData.giftType" :list="giftType"></gl-option>
        </el-form-item>
        <el-form-item v-if="formData.giftType == '1'" prop="giftSum" label="赠送喜糖数量" verify>
          <el-input v-model="formData.giftSum" placeholder="请输入赠送喜糖数量" :maxlength="8"></el-input>
        </el-form-item>
        <el-form-item prop="content" label="说明">
          <el-input type="textarea" :rows="5" :maxlength="5000" v-model="formData.content" placeholder="请输入说明"></el-input>
        </el-form-item>
        
      </el-form> 
    </gl-wrap>
    <gl-flex>
      <el-button type="primary" @click="onSubmit" >确认</el-button>
      <el-button @click="handleClose">关闭</el-button>
    </gl-flex>
  </el-dialog>
</template>

<script>
import { fetchVip, addVip, updateVip } from '@/api/vip'
export default {
  props: {
    itemId: {
      type: String,
      default: ''
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data(){
    return{
      formData: {},
      loading: false,
      oldList: [],
      giftType: [
        {name: '喜糖', value: '1'},
        {name: '实物', value: '2'},
      ],
    }
  },
  watch: {
    visible: {
      handler: function(newVal){
        newVal && this.itemId && this.getData()
      }
    },
  },
  methods: {
    async getData(){
      try{
        this.loading = true
        const res = await fetchVip(this.itemId)
        this.loading = false
        res.data.giftType = res.data.giftType.toString()
        this.formData = res.data
        this.oldList = [res.data.vipLogo]
      }catch(e){
        this.loading = false
      }
    },
    onSubmit(){
      this.$refs.form.validate(async valid => {
        if(!valid) return false
        this.loading = true

        const submitFun = this.itemId ? updateVip : addVip
      
        const res = await submitFun(this.formData)
        this.loading = false
        this.$message.success('操作成功')
        this.handleClose()
        this.$emit('reload')
      })
    },
    handleClose(){
      this.$emit('update:visible', false)
      this.formData = {}
      setTimeout(() => {
        this.$refs.form.resetFields()
      }, 100)
    },
  },
}
</script>

<style>

</style>