<template>
  <div>
    <gl-title title="VIP设置"></gl-title>
    <div style="margin: 18px;">
      <!-- <el-button type="primary" size="mini" @click="mClickAction('mUpdateVisible')"> 添加VIP套餐 </el-button> -->
      <el-button type="primary" size="mini" @click="$router.push({path:'update'})"> 添加VIP套餐 </el-button>
    </div>
    <gl-card v-loading="mLoading">
      <el-table :data="mTableData" style="width: 100%">
        <el-table-column type="index" :index="mIndexMethod" label="序号" align="center" width="44"></el-table-column>
        <el-table-column prop="vipId" label="VIPID" width="160"></el-table-column>
        <el-table-column prop="vipName" label="VIP套餐名称"></el-table-column>
        <!-- <el-table-column prop="vipLogo" label="图标">
          <template slot-scope="scope">
            <el-image
              style="width: 60px; height: 60px"
              :src="scope.row.vipLogo"
              fit="cover"
              :preview-src-list="[scope.row.vipLogo]"
            />
          </template>
        </el-table-column> -->
        <el-table-column prop="vipPrice" label="vip价格"></el-table-column>
        <el-table-column prop="discount" label="VIP购物折扣"></el-table-column>
        <el-table-column label="操作" width="210px" fixed="right">
          <template slot-scope="{ row }">
            <!-- <el-button type="text" @click="mClickAction('mUpdateVisible', row.vipId)"> 编辑 </el-button> -->
            <el-button type="text" @click="$router.push({path:'update',query:{vipId: row.vipId}})"> 编辑 </el-button>
            <el-button type="text" @click="mDelete(row.vipId)"> 删除 </el-button>
          </template>
        </el-table-column>
      </el-table>
      <gl-pagination
        :total="mTotal"
        :current-page="mPageNum"
        @currentChange="mHandleCurrentChange"
        @sizeChange="mHandleSizeChange"
      ></gl-pagination>
    </gl-card>
    <Update :visible.sync="mUpdateVisible" :itemId="mItemId" @reload="mReload"/>
  </div>
</template>

<script>
import ListMixin from '@/mixins/list.mixin.js'
import Update from './update.vue'
import { vipList, deleteVip } from '@/api/vip'
export default {
  mixins: [ListMixin],
  components: {Update},
  mounted(){
    this.mGetListFun = vipList
    this.mDeleteFun = deleteVip
    this.mGetList()
  },
}
</script>

<style>

</style>